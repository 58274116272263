import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSponsors, selectAllSponsorCards } from './reportManagementSlice';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { ReactComponent as Sort } from 'common/Styles/svg_icons/Icons_Filled_exchange-alt_24px.svg';
import { ReactComponent as Search } from 'common/Styles/svg_icons/Icons_Filled_search_24px.svg';
import { ReportsCard } from './reportsCard/ReportsCard';
import styles from './reportManagement.module.scss';
import { SponsorCard } from 'common/Types/ReportMgmtTypes';
import { useAppSelector } from 'state/hooks';

type OrderVal = 1 | -1;

export const ReportManagement = (): JSX.Element => {
  const dispatch = useDispatch();
  const sponsors = useAppSelector(selectAllSponsorCards);
  const [searchVal, setSearchVal] = useState<string>('');
  const [sortVal, setSortVal] = useState<number>(0);

  useEffect(() => {
    if (!sponsors.length) dispatch(fetchSponsors());
  }, []);

  const sortTitle = (): string => {
    switch (sortVal) {
      case 1:
        return 'A-Z';
      case 2:
        return 'Z-A';
      default:
        return '';
    }
  }

  const alphaNumSort = (order: OrderVal, array: SponsorCard[]) => array.sort((x, y) => {
    const sponsorX = x.cardSponsor.name.toLocaleLowerCase();
    const sponsorY = y.cardSponsor.name.toLocaleLowerCase();
    return order === 1 ? sponsorX.localeCompare(sponsorY) : sponsorY.localeCompare(sponsorX);
  });

  const applySearchTerm = (array: SponsorCard[], searchTerm: string): SponsorCard[] =>
    array.filter(sponsor =>
      sponsor.cardSponsor.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


  const sortedSponsors = useMemo(() => {
    let sortedArray = applySearchTerm(Object.values(sponsors).filter((sponsor): sponsor is SponsorCard => sponsor !== undefined), searchVal);
    if (sortVal !== 0) sortedArray = alphaNumSort(sortVal === 1 ? 1 : -1, sortedArray);
    return sortedArray;
  }, [sponsors, searchVal, sortVal]);

  const incrementFilter = () => {
    setSortVal(sortVal < 2 ? sortVal + 1 : 0);
  }

  const renderSponsorCards = () => {
    if (!sponsors || Object.keys(sponsors).length === 0) {
      return (<div className={"progress_bar"} ><CircularProgress /></div>);
    }
    return sortedSponsors.map((sponsorCard) => (
      <ReportsCard card={sponsorCard} key={sponsorCard.cardSponsor.id} />
    ));
  };

  return (
    <Box>
      <Box className={styles.stack_control}>
        <TextField
          placeholder="Search Sponsor Name"
          value={searchVal}
          className={styles.sponsor_search}
          InputProps={{
            startAdornment: <Search className={styles.sponsor_search} />,
          }}
          onChange={(event) => setSearchVal(event.target.value)}
          variant="standard"
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography className={styles.sort_title}>{sortTitle()}</Typography>
          <IconButton onClick={incrementFilter}>
            <Sort className={styles.sort_button} title="Sort alphabetically" />
          </IconButton>
        </Box>
      </Box>
      {Object.values(sponsors).length ? (
        <Stack spacing={2}>{renderSponsorCards()}</Stack>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}