import { createSlice, createAsyncThunk, createEntityAdapter, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { sponsorAppsRequest, sponsorsRequest, saveReportPackageElections } from './reportManagementApi';
import {
  Sponsor,
  SponsorApplicationDTO,
  SponsorCard,
  ReportPackageDTO,
  ElectionsDTO,
  AppRowDTO
} from 'common/Types/ReportMgmtTypes';
import { RootState } from 'state/store';
import configData from 'config.json';
import { appTelemetry } from 'services/TelemetryService';
import { apiReqEvnt, apiReqMsg, apiResEvnt, apiResMsg } from 'common/Utils/telemetryUtil';
import { UserState } from 'redux-oidc';
import { GatewayState } from 'gatewaySlice';

export interface ReportManagementState extends EntityState<SponsorCard> {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const sponsorCardAdapter = createEntityAdapter<SponsorCard>({
  selectId: (sponsorCard) => sponsorCard.cardSponsor.id.toString(), 
});

const initialState: ReportManagementState = sponsorCardAdapter.getInitialState({
  status: 'idle', 
});

export const fetchSponsorApps = createAsyncThunk(
  'reportManagement/sponsorAppRequest',
  async (sponsor: Sponsor, { getState }) => {
    const { oidc, gateway } = getState() as { oidc: UserState, gateway: GatewayState };
    const url = `${configData.ENDPOINT_ALX_APIBASEURL}/report-admin/application/${sponsor.id}`;
    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url
    });
    
    const response = await sponsorAppsRequest(sponsor).then((res) => {
      appTelemetry(apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url
      });

      return res;
    });

    return response;
  }
)

export const fetchSponsors = createAsyncThunk(
  'reportManagement/sponsorsRequest',
  async (_, { getState }) => {
    const { oidc, gateway } = getState() as { oidc: UserState, gateway: GatewayState };
    const url = `${configData.ENDPOINT_ALX_APIBASEURL}/report-admin/sponsor`;
    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url
    });

    const response = await sponsorsRequest().then((res) => {
      appTelemetry(apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url
      });
      return res;
    });

    return response;
  }
)

export const saveElections = createAsyncThunk(
  'reportManagement/saveElections',
  async (sponsorId: number, {getState}) => {
    const { reportManagement, oidc, gateway } = getState() as { reportManagement: ReportManagementState, oidc: UserState, gateway: GatewayState };
    const electionList: SponsorApplicationDTO[] = Object.values(reportManagement.entities[sponsorId]?.pendingReportPackageElections || {});

    const dto: ElectionsDTO = {
      sponsorId,
      electionList
    }
    const response = await saveReportPackageElections(dto);
    return response;
  }
);

export const reportManagementSlice = createSlice({
  name: 'reportManagement',
  initialState,
  reducers: {
    electReportPackageByApp(state, action: PayloadAction<ReportPackageDTO>) {
      const { sponsorId, sponsorAppId, reportPackageId, election } = action.payload;
      const sponsorCard = state.entities[sponsorId];
      if (sponsorCard) {
        const sponsorApp = sponsorCard.cardApps[sponsorAppId];
        if (sponsorApp && sponsorApp.reports[reportPackageId]) {
          sponsorApp.reports[reportPackageId].isActive = election;
          const clone: SponsorApplicationDTO = {
            ...sponsorApp,
            reports: Object.values(sponsorApp.reports)
          }
          sponsorCard.pendingReportPackageElections[sponsorAppId]=clone;
        }
      }      
    },
    setSelectedAppRows(state, action: PayloadAction<AppRowDTO>) {
      const { sponsorId, rows } = action.payload;
      const sponsorCard = state.entities[sponsorId];
      if (sponsorCard) {
        sponsorCard.selectedAppRows = rows;
      }
    },
    electAllByReport(state, action: PayloadAction<ReportPackageDTO>) {
      const { sponsorId, sponsorAppId, reportPackageId, election } = action.payload;
      const sponsorCard = state.entities[sponsorId];
      if (sponsorCard) {
        const sponsorApp = sponsorCard.cardApps[sponsorAppId];
        if (sponsorApp) {
          Object.values(sponsorApp.reports).forEach(report => {
            if (report.id === reportPackageId) {
              report.isActive = election;
            }
          });
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSponsorApps.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSponsorApps.fulfilled, (state, action) => {
        state.status = 'idle';
        sponsorCardAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchSponsorApps.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchSponsors.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSponsors.fulfilled, (state, action) => {
        state.status = 'succeeded';
        sponsorCardAdapter.setAll(state, action.payload);
      })
      .addCase(fetchSponsors.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { electReportPackageByApp, setSelectedAppRows, electAllByReport } = reportManagementSlice.actions;

export const selectAllSponsorCards = (state: RootState) => state.reportManagement.entities;
export const selectAsyncStatus = (state: RootState) => state.reportManagement.status;

export default reportManagementSlice.reducer;